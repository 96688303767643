<template>
  <div class="modal open">
    <div class="modal_box">
      <div class="header">
        <h4>この研修会は塾生以外の方にも<br class="sp">ご参加いただけます</h4>
        <div class="close modal_close" v-on:click="$emit('cancel')"></div>
      </div>
      <div class="inner">
        <p>
          <span class="blue">こちらの研修会は複眼経済塾の塾生以外の方もご参加いただけます。</span><br><br>
          複眼経済塾塾生の方は「ログイン」ボタンより複眼経済塾にログインの上参加申し込みを行ってください。<br><br>
          塾生以外の方は「塾生以外の方はこちら」ボタンを押して参加申し込みを行う、または「複眼経済塾に入塾する」ボタンを押して複眼経済塾に入塾してください。
        </p>

        <div class="btn_wrap">
          <p class="bold blue alnC mb10">複眼経済塾の塾生の方はこちら</p>
          <router-link :to="{ name: 'UsersSignIn'}" class="submit light">ログイン</router-link>
        </div>
        <div class="btn_wrap">
          <p class="bold alnC mb10">複眼経済塾の塾生以外の方はこちらへ</p>
          <router-link :to="{ name: 'WorkshopForm', params: {id: target_id}}" class="submit light skeleton">塾生以外の方はこちら</router-link>
        </div>
        <div class="btn_wrap">
          <p class="bold alnC mb10">複眼経済塾への入会を<br class="sp">希望される方はこちら</p>
          <router-link :to="{ name: 'UsersRegistration'}" class="submit green">複眼経済塾に入塾する</router-link>
        </div>
        <div class="btn_wrap">
          <p class="bold alnC mb10">参加を希望しない方は<br class="sp">ウィンドウを閉じてください</p>
          <a href="javascript:void(0);" class="submit gray modal_close" v-on:click="$emit('cancel')">ウィンドウを閉じる</a>
        </div>
      </div>
    </div>
    <div class="ovr"></div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  props: {
    target_id: null
  },
}
</script>
