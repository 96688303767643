<template>
  <div class="modal open">
    <div class="modal_box">
      <div class="header">
        <h4>こちらの研修会は<br>
          <span class="class" v-if="model.rank_regular_plus">本科プラス</span>
          <span class="class" v-if="model.rank_regular || model.rank_regular_default">本科</span>
          <span class="class" v-if="model.rank_preparatory">予科</span>
          <span class="class" v-if="model.rank_student">学生科</span>
          <span class="class" v-if="false /*model.rank_corporation*/">法人科</span>
          <br>の方のみ参加いただけます</h4>
        <div class="close modal_close" v-on:click="$emit('cancel')"></div>
      </div>
      <div class="inner">
        <dl class="entry_condition flex">
          <dt>現在の塾生クラス</dt>
          <dd>
            <div class="cl_box" v-bind:class="'class' + rank_num">{{ current_user.rank_name }}</div>
          </dd>
        </dl>
        <dl class="entry_condition flex">
          <dt><span class="red">必要な塾生クラス</span></dt>
          <dd>
            <div class="cl_box class01" v-if="model.rank_regular_plus">本科プラス</div>
            <div class="cl_box class02" v-if="model.rank_regular || model.rank_regular_default">本科</div>
            <div class="cl_box class03" v-if="model.rank_preparatory">予科</div>
            <div class="cl_box class05" v-if="model.rank_student">学生科</div>
            <div class="cl_box class06" v-if="false /*model.rank_corporation*/">法人科</div>
          </dd>
        </dl>

        <p class="red">「{{ current_user.rank_name }}」の方はこちらの研修会には参加出来ません。</p>
        <p>
          下の「塾生クラスの変更」ボタンより「{{ current_user.rank_name }}」以上へのアップグレードを行ってください。
        </p>

        <div class="btn_wrap">
          <router-link :to="{ name: 'UsersCustomerRankChange'}" class="submit light">塾生クラスの変更</router-link>
        </div>
        <div class="btn_wrap">
          <p class="bold alnC mb10">クラスの変更を希望されない方は<br class="sp">ウィンドウを閉じてください</p>
          <a href="javascript:void(0);" class="submit gray modal_close" v-on:click="$emit('cancel')">ウィンドウを閉じる</a>
        </div>
      </div>
    </div>
    <div class="ovr"></div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      rank_num: '01',
    }
  },
  props: {
    model: null
  },
  mounted() {
    switch (this.current_user.rank) {
      case 'rank_regular_plus_month':
      case 'rank_regular_plus_year':
        this.rank_num = '01'
        break
      case 'rank_regular_month':
      case 'rank_regular_year':
      case 'rank_regular_shikiho_premium_year':
      case 'rank_regular_shikiho_basic_year':
        this.rank_num = '02'
        break
      case 'rank_preparatory_month':
      case 'rank_default':
      case 'rank_regular_default_shikiho_premium_month':
      case 'rank_regular_default_shikiho_basic_month':
        this.rank_num = '03'
        break
      case 'rank_student_month':
        this.rank_num = '05'
        break
      case 'rank_corporation_month':
        this.rank_num = '06'
        break
      default:
        this.rank_num = '01'
    }
  }
}
</script>
